const coins = {
  KMD: {
    explorer: 'https://kmdexplorer.io/',
    api: [
      'https://kmdexplorer.io/insight-api-komodo/',
      'https://kmd.explorer.dexstats.info/insight-api-komodo/',
    ],
    prices: {
      coinpaprika: 'kmd-komodo',
    },
    enabled: true,
  },
  KOIN: {
    explorer: 'https://koin.explorer.dexstats.info/',
    api: ['https://koin.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },  
  CCL: {
    explorer: 'https://ccl.explorer.dexstats.info/',
    api: ['https://ccl.explorer.dexstats.info/insight-api-komodo/'],
    enabled: false,
  },
  ILN: {
    explorer: 'https://explorer.ilien.io/',
    api: ['https://explorer.ilien.io/insight-api-komodo/'],
    enabled: true,
  },
  THC: {
    explorer: 'https://thc.explorer.dexstats.info/',
    api: ['https://thc.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
  MCL: {
    explorer: 'https://mcl.explorer.dexstats.info/',
    api: ['https://mcl.explorer.dexstats.info/insight-api-komodo/'],
    prices: {
      coinpaprika: 'mcl-marmara-credit-loops',
    },
    enabled: true,
  },
  VRSC: {
    explorer: 'https://insight.verus.io/',
    api: [
      'https://vrsc-explorer.lordofthechains.com/',
      'https://vrsc.explorer.dexstats.info/insight-api-komodo/',
    ],
    prices: {
      coinpaprika: 'vrsc-verus-coin',
    },
    enabled: true,
  },
  CLC: {
    explorer: 'https://clc.explorer.dexstats.info/',
    api: ['https://clc.explorer.dexstats.info/insight-api-komodo/'],
    prices: {
      coinpaprika: 'clc-collider-coin',
    },
    enabled: true,
  },
  GLEEC: {
    explorer: 'https://gleec.xyz/',
    api: [
      'https://gleec.explorer.dexstats.info/insight-api-komodo/',
      'https://gleec.xyz/insight-api-komodo/',
    ],
    prices: {
      coinpaprika: 'gleec-gleec-coin',
    },
    enabled: true,
  },
  TOKEL: {
    explorer: 'https://tokel.explorer.dexstats.info/',
    api: [
      'https://tokel.explorer.dexstats.info/insight-api-komodo/',
    ],
    airdrop: true,
    prices: {
      coinpaprika: 'tkl-tokel',
    },
    enabled: true,
  },
  DOC: {
    explorer: 'https://doc.explorer.dexstats.info/',
    api: [
      'https://doc.explorer.dexstats.info/insight-api-komodo/',
    ],
    enabled: true,
  },
  MARTY: {
    explorer: 'https://marty.explorer.dexstats.info/',
    api: [
      'https://marty.explorer.dexstats.info/insight-api-komodo/',
    ],
    enabled: true,
  },
  MDX: {
    explorer: 'https://mdx.xpl.monster/',
    api: ['https://mdx.xpl.monster/insight-api-komodo/'],
    enabled: true,
  },
  RAPH: {
    explorer: 'https://raph.xpl.monster/',
    api: ['https://raph.xpl.monster/insight-api-komodo/'],
    enabled: true,
  },
  BCZERO: {
    explorer: 'https://bczero.xpl.monster/',
    api: ['https://bczero.xpl.monster/insight-api-komodo/'],
    enabled: true,
  },
    /* Removed from dPoW
SOULJA: {
    explorer: 'https://soulja.explorer.dexstats.info/',
    api: ['https://soulja.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
  DP: {
    explorer: 'https://dp.explorer.dexstats.info/',
    api: ['https://dp.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
    WSB: {
    explorer: 'https://wsb.explorer.dexstats.info/',
    api: [
      'https://wsb.explorer.dexstats.info/insight-api-komodo/',
    ],
    airdrop: true,
    enabled: false,
  },
  SPACE: {
    explorer: 'https://explorer.spaceworks.co/',
    api: ['https://explorer.spaceworks.co/api/'],
    enabled: true,
  },
    LABS: {
    explorer: 'https://labs.explorer.dexstats.info/',
    api: ['https://labs.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
  VOTE2024: {
    explorer: 'https://vote2024.kmdexplorer.io/',
    api: [
      'https://vote.kmdexplorer.io/insight-api-komodo/',
      "https://vote2024.explorer.lordofthechains.com/insight-api-komodo/"
    ],
    airdrop: true,
    enabled: true,
  },
  RICK: {
    explorer: 'https://rick.explorer.dexstats.info/',
    api: [
      'https://rick.explorer.dexstats.info/insight-api-komodo/',
      'https://rick.kmd.dev/insight-api-komodo/',
    ],
    enabled: true,
  },
  MORTY: {
    explorer: 'https://morty.explorer.dexstats.info/',
    api: [
      'https://morty.explorer.dexstats.info/insight-api-komodo/',
      'https://morty.kmd.dev/insight-api-komodo/',
    ],
    enabled: true,
  },
BOTS: {
    explorer: 'https://bots.explorer.dexstats.info/',
    api: ['https://bots.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
  MGW: {
    explorer: 'https://mgw.explorer.dexstats.info/',
    api: ['https://mgw.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
  WLC21: {
    explorer: 'https://wlc21.explorer.dexstats.info/',
    api: ['https://wlc21.explorer.dexstats.info/insight-api-komodo/'],
    enabled: false,
  },
  COQUICASH: {
    explorer: 'https://coquicash.explorer.dexstats.info/',
    api: ['https://coquicash.explorer.dexstats.info/insight-api-komodo/'],
    enabled: false,
  },
  BTCH: {
    explorer: 'https://btch.explorer.dexstats.info/',
    api: ['https://btch.explorer.dexstats.info/insight-api-komodo/'],
    enabled: false,
  },
  NINJA: {
    explorer: 'https://ninja.explorer.dexstats.info/',
    api: ['https://ninja.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
 BET: {
    explorer: 'https://bet.explorer.dexstats.info/',
    api: ['https://bet.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
  CRYPTO: {
    explorer: 'https://crypto.explorer.dexstats.info/',
    api: ['https://crypto.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
  HODL: {
    explorer: 'https://hodl.explorer.dexstats.info/',
    api: ['https://hodl.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
PGT: {
    explorer: 'https://pgt.explorer.dexstats.info/',
    api: ['https://pgt.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
  MSHARK: {
    explorer: 'https://mshark.explorer.dexstats.info/',
    api: ['https://mshark.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
  REVS: {
    explorer: 'https://revs.explorer.dexstats.info/',
    api: ['https://revs.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
  PANGEA: {
    explorer: 'https://pangea.explorer.dexstats.info/',
    api: ['https://pangea.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
  JUMBLR: {
    explorer: 'https://jumblr.explorer.dexstats.info/',
    api: ['https://jumblr.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
  MESH: {
    explorer: 'https://mesh.explorer.dexstats.info/',
    api: ['https://mesh.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
  DEX: {
    explorer: 'https://dex.explorer.dexstats.info/',
    api: ['https://dex.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
  SUPERNET: {
    explorer: 'https://supernet.explorer.dexstats.info/',
    api: ['https://supernet.explorer.dexstats.info/insight-api-komodo/'],
    enabled: true,
  },
    AXO: {
    explorer: 'https://axo.explorer.dexstats.info/',
    api: ['https://axo.explorer.dexstats.info/insight-api-komodo/'],
    enabled: false,
  },
   */
  
  /* coins below need special handling due to no overwinter support
  ZILLA: {
    explorer: 'https://zilla.explorer.dexstats.info/',
    api: ['https://zilla.explorer.dexstats.info/insight-api-komodo/'],
  },
  OOT: {
    explorer: 'https://oot.explorer.dexstats.info/',
    api: ['https://oot.explorer.dexstats.info/insight-api-komodo/'],
  },
  */
};

module.exports = coins;